.navbar {
    background-color: #081D1E;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* height: 50px; */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* Add box shadow */

    border-bottom: 2px solid #18CE9C;
}

.navbar-logo img {
    max-height: 40px;
    padding: 10px; 
}

.navbar-links {
    display: flex;
    /* Display links in a row */
    flex-direction: row;
    /* Ensure links are arranged horizontally */
    list-style-type: none;
}

.navbar-links ul {
    margin: 0;  
    padding: 0;
    display: flex;
    /* Display links in a row */
    flex-direction: row;
    /* Ensure links are arranged horizontally */
}

.navbar-links li {
    margin-right: 20px;
}

.navbar-links a {
    text-decoration: none;
    color: white;
    font-weight: bold;
}