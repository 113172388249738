.custom-marker{
  width: 20px;
  background-color: #081D1E;
  border-radius: 50%;
  cursor: pointer;
}

.map-container {
  height: 100vh;
  flex-grow: 1;
}

.project-card {
  border-radius: 8px;
  margin: 15px 15px;
  padding: 10px 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  cursor: pointer;
}

.project-info {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.project-type,
.project-country {
  font-size: 14px;
}

.project-list {
  max-height: 100vh;
  overflow-y: auto;
  box-sizing: border-box;
}

.section-title {
  padding: 10px 15px;
  padding-bottom: 0px;
  color: #081D1E;
  font-weight: bold;
  font-size: 25px;
}


.project-title {
  font-family: 'Helvetica', sans-serif;
  color: #1A857A;
  font-weight: bold;
}

.project-developer {
  color: #27C18B;
  font-size: 14px;
}

.projects-count {
  color: #27C18B;
  font-size: 15px;
  line-height: 25px;
  vertical-align: middle;
  /* Vertically aligns the content */
}

.project-description {
  padding-top: 3px;
  padding-right: 3px;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;

  min-width: 0;
  font-size: 12px;
  color: rgb(92, 97, 117);
}

.project-details {
  width: 100%;
  max-width: 500px;
}

.project-description::after {
  content: '...';
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), white 50%);
}


/* Mobile CSS Breakpoints */
@media (max-width: 576px) {
  /* Mobile-specific CSS rules */

  .img-wrapper {
    min-width: 130px;
    max-width: 130px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .project-description {
    min-width: 0;
    min-height: 60px;
    max-height: 60px;
  }



  .project-developer {
    letter-spacing: -0.5px;
  }

}

@media (min-width: 577px) and (max-width: 768px) {
  /* Small tablet-specific CSS rules */
  

}

/* Web CSS Breakpoints */
@media (min-width: 769px) and (max-width: 992px) {
  /* Medium-sized tablet and small desktop-specific CSS rules */
}

@media (min-width: 993px) and (max-width: 1200px) {

  /* Large desktop-specific CSS rules */
  .img-wrapper {
    min-width: 200px;
    max-width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }


  .project-description {
    min-width: 0;
    min-height: 76px;
    max-height: 76px;
  }

}

@media (min-width: 1201px) {

  /* Extra-large desktop-specific CSS rules */
  .img-wrapper {
    min-width: 200px;
    max-width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .project-description {
    min-width: 0;
    min-height: 76px;
    max-height: 76px;
  }

}



/* Maybe we can set a minimum height for the cards here
 */

.project-description-wrapper {
  position: relative;
}

.project-body {
  display: flex;
  gap: 10px;
  max-height: 180px;
}



.flash {
  animation: flash-animation 1s;
}

@keyframes flash-animation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


.img-rounded {
  border-radius: 10px;
  width: 100% !important;
  /* make it responsive */
}

.rrow {
  display: flex;
  gap: 10px;

}

.expanded {

  flex-grow: 1;
}